import React, { FC, useMemo } from 'react';

import { Product } from '@lib/offers/types';
import { Box, Stack } from '@mui/material';
import { usePurchaseInfo } from '@templates/purchase/common/PurchaseInfoContext';
import { ImageContainer } from '@templates/purchase/common/select-parent-product-step/parent-product-list/parent-product-card/ImageContainer';
import { TextLines } from '@templates/purchase/common/select-parent-product-step/parent-product-list/parent-product-card/TextLines';
import { Price } from '@ui/price/Price';

import { CtaButton } from '../CtaButton';
import { ProductName } from '../ProductName';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useRouter } from 'next/router';
import { PurchaseQueryParams } from '@templates/purchase/common/types';
import { filterExpired, isProductNotExpired } from '@templates/common/helpers';

interface Props {
  product: Product;
  productGroupId: string;
}

export const ProductBox: FC<Props> = ({ product, productGroupId }) => {
  const { selectedProducts, setSelectedProducts, selectedBundles } =
    usePurchaseInfo();
  const router = useRouter();
  const { offerId }: PurchaseQueryParams = router.query;

  const { selectedSubscription } = useSubscriptions();
  const isProductSelected = useMemo(
    () =>
      selectedProducts[productGroupId]?.some(
        (x) => x.productId === product.productId,
      ),
    [product.productId, productGroupId, selectedProducts],
  );
  const isBundleActivated = useMemo(() => {
    return selectedBundles.some((bundle) =>
      bundle.products.some(
        (bundleProduct) => bundleProduct.productId === product.productId,
      ),
    );
  }, [product.productId, selectedBundles]);

  return (
    <Stack
      sx={{
        borderRadius: '16px',
        border: '1px solid #E6E6E6',
        height: '100%',
        width: '100%',
      }}
    >
      {product.showImage && <ImageContainer image={product.image} />}
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ mb: 3 }}>
          <ProductName name={product.commercialName} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Price
            sx={{ justifyContent: 'space-between' }}
            pricing={product.productPricing}
          />
        </Box>
        <TextLines tags={product.tags?.filter((tag) => tag.label)} />
        <Box flexGrow={1} />
        <Box display="flex" alignSelf="center" mt={2}>
          <CtaButton
            isAdded={isProductSelected}
            isBundleActivated={isBundleActivated}
            onAdd={handleAdd}
            onRemove={handleRemove}
            sx={{ padding: '4px 24px' }}
          />
        </Box>
      </Box>
    </Stack>
  );

  function handleAdd() {
    const selectedProductGroupExists = !!selectedProducts[productGroupId];

    const activeAndValidSubscriptionProduct =
      selectedSubscription?.products?.find(
        (product) =>
          isProductNotExpired(product) && product?.status === 'ACTIVE',
      );
    const isExistingAddonProduct =
      activeAndValidSubscriptionProduct?.children?.some(
        (prod) =>
          prod?.productId === product?.productId &&
          filterExpired(prod) &&
          prod?.status === 'ACTIVE',
      ) && !offerId;

    const updatedProduct = { ...product, existing: isExistingAddonProduct };

    const updateSelectedProducts = (prev) => ({
      ...prev,
      [productGroupId]: selectedProductGroupExists
        ? [...prev[productGroupId], updatedProduct]
        : [updatedProduct],
    });

    setSelectedProducts(updateSelectedProducts);
  }

  function handleRemove() {
    setSelectedProducts((prev) => {
      const productGroupSelections = prev[productGroupId].filter(
        (x) => x.productId !== product.productId,
      );
      const updated = { ...prev };

      if (productGroupSelections.length) {
        updated[productGroupId] = productGroupSelections;
      } else {
        delete updated[productGroupId];
      }

      return updated;
    });
  }
};
