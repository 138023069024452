import { Order, SupportedPaymentTypes } from '@lib/payment/types';
import { IndexSignature } from '@lib/types';

import { SimValidateRo } from '../sim/types';
import { ProductPricing } from '@lib/offers/types';
import { TableResponse } from '@ui/table/types';

export interface PostSubmitOrderRo {
  id: string;
  tenantId: string;
}

export interface PaymentUpdateRo {
  orderStatus: string;
  failureDetails: FailureDetails;
}

export type GetOrderRo = Order;

export type GetOrderRouteParams = IndexSignature<{
  orderId: string;
}>;

export interface OrderSelectedProduct {
  productId: string;
  productGroupId: string;
}

export interface FailureDetails {
  reason: string;
  description: string;
}

export interface ChangeInventoryOrderDto {
  subscriptionId: string;
  identifier?: string;
  orderProductId: string;
  from: From;
  to: To;
  reason?: string;
}

interface To {
  inventoryTypeId: string;
  inventoryTypeVariantId?: string;
}

interface From {
  inventoryTypeId: string;
  item: Item;
}

/**
 * @deprecated
 */
export interface PostSubmitOrderDto {
  offer: Offer;
  orderPaymentReq?: OrderPaymentReq;
}

export interface OrderPaymentReq {
  callbackUrl: string;
  paymentType: SupportedPaymentTypes;
}

export interface PaymentUpdateReq {
  callbackUrl: string;
  paymentType: SupportedPaymentTypes;
}

export interface Offer {
  offerId: string;
  activationDate?: string;
  productGroups: ProductGroup[];
}

export interface ProductGroup {
  productGroupId: string;
  selectedProducts: SelectedProduct[];
}

export interface ProductFieldValue {
  orderProductFieldId: string;
  code: string;
  name: string;
  type: 'STRING' | 'NUMBER' | 'STRING_ARRAY' | 'BOOLEAN';
  identifier: boolean;
  value?: string | number | boolean | string[];
  values?: string[];
}

export interface SelectedProduct {
  existing?: boolean;
  children?: SelectedProduct[];
  prismicParentId?: string;
  pricingId: string;
  productId: string;
  // TODO: Parent id doesn't exist in response, we need to remove it
  parentId?: string;
  productGroupId?: string;
  subscriptionId?: string;
  orderProductId?: string;
  orderPortInReq?: PortIn;
  inventoryTypes?: InventoryType[];
  simCardReq?: Omit<SimValidateRo, 'orderId'>;
  productPricing: ProductPricing;
  commercialName: string;
  productFieldValues?: ProductFieldValue[];
}

export interface AddOrderOffersRequest {
  orderId: string;
  offers: OrderOfferForm[];
}

export interface OrderOfferForm {
  offerId: string;
  activationDate?: string;
  productGroups: OrderGroupForm[];
}

export interface OrderGroupForm {
  productGroupId: string;
  selectedProducts: OrderProductForm[];
}

export interface OrderProductForm {
  productId: string;
  parentId?: string;
  pricingId: string;
  subscriptionId?: string;
  orderProductId?: string;
  existing?: boolean;
}

interface InventoryType {
  orderInventoryTypeId: string;
  inventoryType: string;
  inventoryTypeName: string;
  inputType: string;
  dataType: string;
  inventoryTypeVariants: InventoryTypeVariant[];
  portIn: PortIn;
  item: Item;
}

interface Item {
  inventoryId: string;
  serial?: string;
  deliveryType?: string;
  selectedInventoryTypeVariantId?: string;
  inventoryStatus?: string;
}

interface InventoryTypeVariant {
  id: string;
  name: string;
}

export interface PortIn {
  msisdn: string;
  operator: string;
  type: string;
}

export interface PostSubmitAddOnDto {
  subscriptionId: string;
  orderProductId: string;
  offerId: string;
  addonIds: string[];
  orderPaymentReq?: OrderPaymentReq;
}

export interface PostSubmitAddOnRo {
  id: string;
  tenantId: string;
}

export interface PostSubmitTopUpDto {
  subscriptionId: string;
  moneyAmount: MoneyAmount;
  orderPaymentReq: OrderPaymentReq;
}

interface MoneyAmount {
  amount: number;
  currency: string;
}

export interface PostSubmitTopUpRo {
  id: string;
  tenantId: string;
}

export enum OrderType {
  NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION',
  MODIFY_ADDON = 'MODIFY_ADDON',
  TOP_UP = 'TOP_UP',
  MODIFY_SUBSCRIPTION = 'MODIFY_SUBSCRIPTION',
  CHANGE_CUSTOMER_DATA = 'CHANGE_CUSTOMER_DATA',
  CHANGE_SUBSCRIPTION_STATUS = 'CHANGE_SUBSCRIPTION_STATUS',
  CHANGE = 'CHANGE',
}

export interface Resource {
  subscriptionId: string;
  orderProductId: string;
  inventoryTypeId: string;
  inventoryTypeVariantId?: string;
  delivery?: boolean;
  serial?: string;
  donorMsisdn?: string;
  donorOperator?: string;
  donorServiceType?: string;
}

export type OrdersRo = TableResponse<OrderDetails>;

export interface OrderDetails {
  orderId?: string;
  createdAt?: string;
  orderType?: string;
  orderItems: OrderItem[];
  orderPayment: OrderPaymentAmount;
  orderStatus?: string;
  payable?: boolean;
  hasRecurringFee?: boolean;
}

interface OrderItem {
  subscriptionId?: string;
  orderItemStatus?: string;
  identifier?: string;
}

interface OrderPaymentAmount {
  amount?: Amount;
}

interface Amount {
  amount?: number;
  currency?: string;
}
