import { ProductPricing } from '@lib/offers/types';
import { IndexSignature } from '@lib/types';

interface SubscriptionProductDescription {
  commercialName: string;
  description: string;
}

export type SubscriptionProductStatus =
  | 'PENDING'
  | 'STATUS'
  | 'ACTIVE'
  | 'FAILED'
  | 'SUSPENDED';
export type SubscriptionStatus = 'PENDING' | 'STATUS' | 'ACTIVE' | 'SUSPENDED';

export type SubscriptionType = 'PREPAID' | 'POSTPAID';

export interface SubscriptionProduct {
  offerId: string;
  product: SubscriptionProductDescription;
  prismicParentId?: string;
  productId: string;
  orderProductId: string;
  status: SubscriptionProductStatus;
  contractStartDate?: string;
  contractEndDate?: string;
  validFrom: string;
  validTill?: string;
  productPricing?: ProductPricing;
  children?: SubscriptionProductChild[];
  subscriptionType: SubscriptionType;
}

export type SubscriptionProductChild = Omit<
  SubscriptionProduct,
  'prismicParentId'
>;

type SubscriptionStatusReason =
  | 'NON_PAYMENT'
  | 'CUSTOMER_REQUEST'
  | 'LOST_OR_STOLEN';

export interface Subscription {
  id: string;
  status: SubscriptionStatus;
  statusReason?: SubscriptionStatusReason;
  identifier: string;
  products: SubscriptionProduct[];
  inventory?: InventoryItem[];
  nextBillCycleDate?: string;
}

export interface InventoryItem {
  dataType?: string;
  serial?: string;
  validFrom?: string;
  validTill?: string;
  orderProductId?: string;
  inventoryStatus?: string;
  inventoryType: InventoryType;
  inventoryTypeVariant: InventoryType;
  inventoryId: string;
  inventoryTypeId?: string;
  inventoryTypeVariantId?: string;
  portIn?: PortIn;
}

interface InventoryType {
  name: string;
  id: string;
}

interface PortIn {
  donor: string;
  service: string;
}

export type GetSubscriptionsRo = Subscription[];

export interface GetBalanceRo {
  balances: Balance[];
  cashBalance: CashBalance;
}

interface CashBalance {
  balanceName: string;
  outstandingAmount: number;
  unitType: string;
  expirationDate: string;
  creationDate: string;
  initialAmount: number;
  balanceSource: BalanceSource;
}

export interface Balance {
  balanceName: string;
  outstandingAmount: number;
  unitType: string;
  expirationDate: string;
  creationDate: string;
  initialAmount: number;
  balanceSource: BalanceSource;
}

interface BalanceSource {
  source: string;
  sourceId: string;
  subSourceId: string;
  productId?: string;
  productName?: string;
  productCommercialName?: string;
}

export type GetBalanceRouteParams = IndexSignature<{
  id: string;
}>;

export type GetCdrRouteParams = IndexSignature<{
  id: string;
}>;

export type GetSubscriptionsRouteParams = IndexSignature<{
  subscriptionId: string;
}>;

export interface GetCdrQueryParams {
  startDate: string;
  endDate: string;
}

export type GetCdrRo = Cdr[];

export enum ServiceCategory {
  SMS = 'SMS',
  DATA = 'Data',
  VOICE = 'Voice',
}

export interface Cdr {
  serviceCategory: ServiceCategory;
  direction: string;
  originatingCountry: string;
  destinationCountry: string;
  billPeriod: string;
  startDate: string;
  duration: number;
  recipientNumber: string;
  charge: number;
}
