import { FC } from 'react';
import { DefaultProductAddonsVariation } from '../types';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { ProductAddonsContent } from './ProductAddonsContent';
import { PurchaseInfoProvider } from '@templates/purchase/common/PurchaseInfoContext';

interface Props {
  slice: DefaultProductAddonsVariation;
}

export const DefaultProductAddons: FC<Props> = ({ slice }) => {
  const { paddingTop, paddingBottom, product } = slice.primary;

  return (
    <PurchaseInfoProvider>
      <SliceLayout paddingTop={paddingTop} paddingBottom={paddingBottom}>
        <ProductAddonsContent parentProduct={product} />
      </SliceLayout>
    </PurchaseInfoProvider>
  );
};
